import { Injectable, Signal, computed, inject, signal } from '@angular/core';
import { finalize, of, tap } from 'rxjs';
import {
  Organization,
  OrganizationService,
  PaymentProviderInvoice,
  RemoteControlService,
  Subscription,
  UpdateOrganizationInput
} from '../openapi';

export enum Plans {
  NONE = 'None',
  FREEMIUM = 'Freemium',
  TRIAL = 'Trial',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
}

export enum PaidAdditionalFeatures {
  SSO = 'Sso',
}

export type OrganizationWithChildren = Organization & { children: OrganizationWithChildren[] };
export type OrganizationWithIndent = OrganizationWithChildren & { indent: number };
@Injectable({
  providedIn: 'root',
})
export default class OrganizationState {
  #orgService = inject(OrganizationService);
  #remoteControlService = inject(RemoteControlService);
  #organizations = signal<Organization[]>([]);
  #invoices = signal<PaymentProviderInvoice[]>([]);
  #currentOrganizationId = signal<string | null>(null);

  // Rewrite to flat sorted array to avoid re-rendering
  $organizations = this.#organizations.asReadonly();
  $organizationsWithChildren = computed(() => {
    const orgs = this.#organizations().sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
    return this.mapChildrenToParent(orgs, null);
  });

  private mapChildrenToParent(
    orgs: Organization[],
    parentOrgId: string | null | undefined
  ): OrganizationWithChildren[] {
    const children = orgs.filter((org) => org.parentOrganizationId === parentOrgId);

    return children.map((child) => ({
      ...child,
      children: this.mapChildrenToParent(orgs, child.id),
    }));
  }

  $organizationsWithIndex = computed(() => {
    const rootOrgs = this.$organizationsWithChildren();

    return this.mapOrgsToIndent(rootOrgs, 0);
  });

  private mapOrgsToIndent(orgs: OrganizationWithChildren[], indent: number): OrganizationWithIndent[] {
    const orgsWithIndent: OrganizationWithIndent[] = [];

    orgs.forEach((org) => {
      orgsWithIndent.push({ ...org, indent });

      if (org.children.length > 0) {
        orgsWithIndent.push(...this.mapOrgsToIndent(org.children, indent + 1));
      }
    });

    return orgsWithIndent;
  }

  $activeOrganization = computed(() => {
    if (!this.#currentOrganizationId()) return null;

    const activeOrg = this.#organizations().find((org) => org.id === this.#currentOrganizationId());

    return activeOrg ?? null;
  });
  $invoices = this.#invoices.asReadonly();
  $loadingInvoices = signal<boolean>(false);
  $activeOrganizationId = this.#currentOrganizationId.asReadonly();
  $newProPlan = signal<boolean>(false);
  $activePlan = computed(() => {
    return this.$activeOrganization()?.currentSubscriptionType ?? Plans.NONE;
  }) as Signal<Plans>;
  $hasAutoRenew = computed(() => {
    return this.$activeOrganization()?.currentSubscription?.autoRenews ?? false;
  });
  $hasParentOrg = computed(() => this.$activeOrganization()?.parentOrganizationId);
  $isRootOrg = computed(() => this.$activeOrganization()?.isRootOrganization ?? false);
  $daysUntilSubscriptionExpiry = computed(() => {
    const currentSubscription = this.$activeOrganization()?.currentSubscription;
    return this.daysUntilSubscriptionExpiry(currentSubscription);
  });

  $featureFlags = computed(() => this.$activeOrganization()?.featureFlags ?? []);

  daysUntilSubscriptionExpiry(currentSubscription: Subscription | null | undefined) {
    if (!currentSubscription) {
      return null;
    }

    if (currentSubscription.type !== Plans.PRO && currentSubscription.type !== Plans.ENTERPRISE) {
      return null;
    }

    return Math.ceil((new Date(currentSubscription.to as string).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
  }

  loadInvoices() {
    this.$loadingInvoices.set(true);
    this.#orgService
      .getOrganizationInvoices()
      .pipe(finalize(() => this.$loadingInvoices.set(false)))
      .subscribe((invoices) => {
        this.#invoices.set(invoices);
      });
  }

  initProPlanUpgrade() {
    this.$newProPlan.set(true);
  }

  cancelProPlanUpgrade() {
    this.$newProPlan.set(false);
  }

  setCurrentOrgId(currentOrganizationId: string) {
    this.#currentOrganizationId.set(currentOrganizationId);
  }
  private defaultUpdateOrganizationInput(): UpdateOrganizationInput {
    return {
      addTags: null,
      backupNames: null,
      featureFlags: null,
      machineNames: null,
      name: null,
      removeMachineRegistrationTokens: null,
      removeTags: null,
      settings: null,
      updateMachineRegistrationTokens: null,
    };
  }

  addFeatureFlag(featureFlag: string) {
    return this.#orgService
      .patchOrganization({
        requestBody: {
          ...this.defaultUpdateOrganizationInput(),
          featureFlags: (this.$activeOrganization()?.featureFlags ?? []).concat(featureFlag),
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  removeFeatureFlag(featureFlag: string) {
    return this.#orgService
      .patchOrganization({
        requestBody: {
          ...this.defaultUpdateOrganizationInput(),
          featureFlags: (this.$activeOrganization()?.featureFlags ?? []).filter((flag) => flag !== featureFlag),
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  toggleFeatureFlag(featureFlag: string) {
    if (this.$featureFlags().includes(featureFlag)) {
      return this.removeFeatureFlag(featureFlag);
    }

    return this.addFeatureFlag(featureFlag);
  }

  createMachineRegistrationToken(name: string | null, tags: string[] | null) {
    return this.#remoteControlService
      .postRemotecontrolCreatetoken({
        requestBody: {
          validFor: null,
          name,
          tags,
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  removeMachineRegistrationToken(token: string) {
    return this.#orgService
      .patchOrganization({
        requestBody: {
          ...this.defaultUpdateOrganizationInput(),
          removeMachineRegistrationTokens: [token],
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  updateMachineRegistrationToken(token: string, name: string, tags: string[]) {
    return this.#orgService
      .patchOrganization({
        requestBody: {
          ...this.defaultUpdateOrganizationInput(),
          updateMachineRegistrationTokens: [
            {
              token,
              expires: null,
              name,
              tags,
            },
          ],
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  createNewOrganization(name: string, detached: boolean) {
    return this.#orgService
      .postOrganizations({
        requestBody: {
          name,
          detached,
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  deleteOrganization(organization: Organization) {
    return this.#orgService
      .deleteOrganizationsByOrganizationId({
        organizationId: organization.id ?? '',
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  getOrganisation(options?: { returnSubscription?: boolean; refreshContent?: boolean }) {
    const returnSubscription = options?.returnSubscription ?? false;
    const refreshContent = options?.refreshContent ?? false;

    if (returnSubscription && this.$activeOrganization() && !refreshContent) {
      return of([this.$activeOrganization()]);
    } else if (!returnSubscription && this.$activeOrganization() && !refreshContent) {
      return [this.$activeOrganization()];
    }

    const sub = this.#orgService.getOrganizations().pipe(
      tap((res) => {
        if (res) {
          this.#organizations.set(res);
        }
      })
    );

    if (returnSubscription) {
      return sub;
    }

    sub.subscribe();
  }

  updateOrganization(organization: Pick<Organization, 'name'>) {
    return this.#orgService
      .patchOrganization({
        requestBody: {
          ...this.defaultUpdateOrganizationInput(),
          name: organization.name,
        },
      })
      .pipe(tap(() => this.getOrganisation({ refreshContent: true })));
  }

  cancelSubscription() {
    return this.#orgService.deleteOrganizationSubscription();
  }

  changeSubscription(machineCount: number) {
    return this.#orgService.putOrganizationSubscription({
      requestBody: {
        quantity: machineCount,
      },
    });
  }

  goPro(machineCount: number) {
    return this.#orgService.postOrganizationPaymentsession({
      requestBody: {
        quantity: machineCount,
      },
    });
  }

  patchMachineName(machineId: string, name: string | null) {
    return this.#orgService.patchOrganization({
      requestBody: {
        ...this.defaultUpdateOrganizationInput(),
        machineNames: {
          [machineId]: name,
        },
      },
    });
  }
}
